.section1 {
    padding: 100px 5%;
    color: #fff;
  }
  
  .section1 .text-section {
    text-align: center;
    font-size: 30px;
  }

  .bort-title{
    font-size:70px;
    margin-bottom: 30px;
  }


  .bort-title2{
    font-size:70px;
    margin-bottom: 30px;
  }


  .section1 .image-section img {
    max-width: 100%;
    height: auto;
  }
  
  @media (min-width: 992px) {
    .section1 .text-section {
      text-align: left;
    }
    
    .section1 .image-section {
      display: flex;
      justify-content: flex-end;
    }

    .bort-title{
      font-size:120px;
      margin-bottom: 10px;
    }

    .bort-title2{
      font-size:45px;
      margin-bottom: 50px;
    }

  }
  

  .vibration{
    max-height: 700px;
  }